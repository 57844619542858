import React from "react";
import Styles from './Input.module.css';

export function Input({ name, value, onChange, label }) {
  return (
    <div className={Styles.inputGroup}>
      <label
        htmlFor={name}
        className={Styles.label}
      >
        {label.toUpperCase()}
      </label>
      <input
        className={Styles.input}
        type="text"
        name={name}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

export default Input;
