import React from 'react'

function Button({ onClick, children }) {
    return (
        <button
          onClick={onClick}
          className=" bg-gradient-to-r from-purple-600 to-fuchsia-600 px-5 py-3 text-white text-md rounded shadow-md shadow-purple-900 mb-4"
        >
          { children }
        </button>
    )
}

export default Button
