import React from 'react'
import Styles from './Nav.module.css'
import { Link } from 'react-router-dom'

export function NavLink ({ to, children }) {
    return(
        <li><Link to={ to } className={Styles.link} >{ children }</Link></li>
    )
}

function Nav({ children }) {
    const toggleMenu = e => {
        e.preventDefault();
        document.querySelector('header nav ul').classList.toggle(Styles.active)
    }

    return (
        <nav className=' relative'>
            <button onClick={ toggleMenu } className={Styles.btn}>Menu</button>
            <ul className={`${Styles.nav}`}>
                { children }
            </ul>
        </nav>
    )
}

export default Nav
