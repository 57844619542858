import React from "react";
import { useNavigate } from "react-router-dom";

function Scheam() {
  const navigate = useNavigate();

  const addNewScheam = (e) => {
    e.preventDefault();
    console.log("this fired!");
    navigate("/scheams/new");
  };

  return (
    <>
      <div className=" bg-[#22212c] py-5">
        <h1 className=" text-purple-600 text-5xl font-bold">Scheams</h1>
      </div>
      <svg viewBox="0 0 1792 335" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="rgba(34, 33, 44, 1)"
          d="M 0 50 C 259 50 259 209 518 209 L 518 209 L 518 0 L 0 0 Z"
          stroke-width="0"
        ></path>{" "}
        <path
          fill="rgba(34, 33, 44, 1)"
          d="M 517 209 C 800 209 800 96 1083 96 L 1083 96 L 1083 0 L 517 0 Z"
          stroke-width="0"
        ></path>{" "}
        <path
          fill="rgba(34, 33, 44, 1)"
          d="M 1082 96 C 1437 96 1437 189 1792 189 L 1792 189 L 1792 0 L 1082 0 Z"
          stroke-width="0"
        ></path>
      </svg>
      <div className=" px-5">
        <button
          onClick={addNewScheam}
          className=" bg-gradient-to-r from-purple-600 to-fuchsia-600 px-5 py-3 text-white text-md rounded shadow-md shadow-purple-900 mb-6"
        >
          Add New
        </button>
        <ul className=" mb-6 mx-8 rounded-xl border-b-[0.5px] bg-[#3db95c] border-[#3db95c] border-4 text-white uppercase text-left text-md shadow-2xl shadow-[#36a151]/40">
          <li>
            <a className=" py-3 px-5 block mb-[5px] rounded-xl bg-[#1d1e26]">
              Ajay Lal{" "}
              <span className=" float-right text-[#3ec35f] ">12/12/2021</span>
              <br />
              <small className=" text-slate-400">SP 125 BS VI</small>
            </a>
          </li>
          <li>
            <a className=" py-3 px-5 block mb-[5px] rounded-xl bg-[#1d1e26]">
              Ajay Lal{" "}
              <span className=" float-right text-[#3ec35f] ">12/12/2021</span>
              <br />
              <small className=" text-slate-400">SP 125 BS VI</small>
            </a>
          </li>
          <li>
            <a className=" py-3 px-5 block mb-[5px] rounded-xl bg-[#1d1e26]">
              Ajay Lal{" "}
              <span className=" float-right text-[#3ec35f] ">12/12/2021</span>
              <br />
              <small className=" text-slate-400">SP 125 BS VI</small>
            </a>
          </li>
          <li>
            <a className=" py-3 px-5 block mb-[5px] rounded-xl bg-[#1d1e26]">
              Ajay Lal{" "}
              <span className=" float-right text-[#3ec35f] ">12/12/2021</span>
              <br />
              <small className=" text-slate-400">SP 125 BS VI</small>
            </a>
          </li>
          <li>
            <a className=" py-3 px-5 block mb-[5px] rounded-xl bg-[#1d1e26]">
              Ajay Lal{" "}
              <span className=" float-right text-[#3ec35f] ">12/12/2021</span>
              <br />
              <small className=" text-slate-400">SP 125 BS VI</small>
            </a>
          </li>
          <li>
            <a className=" py-3 px-5 block mb-[5px] rounded-xl bg-[#1d1e26]">
              Ajay Lal{" "}
              <span className=" float-right text-[#3ec35f] ">12/12/2021</span>
              <br />
              <small className=" text-slate-400">SP 125 BS VI</small>
            </a>
          </li>
          <li>
            <a className=" py-3 px-5 block mb-[5px] rounded-xl bg-[#1d1e26]">
              Ajay Lal{" "}
              <span className=" float-right text-[#3ec35f] ">12/12/2021</span>
              <br />
              <small className=" text-slate-400">SP 125 BS VI</small>
            </a>
          </li>
          <li>
            <a className=" py-3 px-5 block mb-[5px] rounded-xl bg-[#1d1e26]">
              Ajay Lal{" "}
              <span className=" float-right text-[#3ec35f] ">12/12/2021</span>
              <br />
              <small className=" text-slate-400">SP 125 BS VI</small>
            </a>
          </li>
          <li>
            <a className=" py-3 px-5 block mb-[5px] rounded-xl bg-[#1d1e26]">
              Ajay Lal{" "}
              <span className=" float-right text-[#3ec35f] ">12/12/2021</span>
              <br />
              <small className=" text-slate-400">SP 125 BS VI</small>
            </a>
          </li>
          <li>
            <a className=" py-3 px-5 block mb-[5px] rounded-xl bg-[#1d1e26]">
              Ajay Lal{" "}
              <span className=" float-right text-[#3ec35f] ">12/12/2021</span>
              <br />
              <small className=" text-slate-400">SP 125 BS VI</small>
            </a>
          </li>
          <li>
            <a className=" py-3 px-5 block mb-[5px] rounded-xl bg-[#1d1e26]">
              Rekha Manoj{" "}
              <span className=" float-right text-[#3ec35f] ">12/12/2021</span>
              <br />
              <small className=" text-slate-400">
                Activa 125 Drum Alloy BS VI
              </small>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Scheam;
