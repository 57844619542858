import React from 'react'

function Dashboard() {
    const handleClick = e => {
        console.log('A Button Was Clicked!')
    }
    return (
        <>
        <div className=" bg-[#22212c] py-5">
          <h1 className=" text-purple-600 text-5xl font-bold">Dashboard</h1>
        </div>
        <svg viewBox="0 0 1792 335" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="rgba(34, 33, 44, 1)"
            d="M 0 50 C 259 50 259 209 518 209 L 518 209 L 518 0 L 0 0 Z"
            stroke-width="0"
          ></path>{" "}
          <path
            fill="rgba(34, 33, 44, 1)"
            d="M 517 209 C 800 209 800 96 1083 96 L 1083 96 L 1083 0 L 517 0 Z"
            stroke-width="0"
          ></path>{" "}
          <path
            fill="rgba(34, 33, 44, 1)"
            d="M 1082 96 C 1437 96 1437 189 1792 189 L 1792 189 L 1792 0 L 1082 0 Z"
            stroke-width="0"
          ></path>
        </svg>
        <div className=" px-5 flex justify-around">
          <button
            onClick={handleClick}
            className=" bg-gradient-to-r from-purple-600 to-fuchsia-600 px-5 py-3 text-white text-md rounded shadow-md shadow-purple-900 mb-4"
          >
            HDFC BANK
          </button>
          <button
            onClick={handleClick}
            className=" bg-gradient-to-r from-purple-600 to-fuchsia-600 px-5 py-3 text-white text-md rounded shadow-md shadow-purple-900 mb-4"
          >
            PADD
          </button>
          <button
            onClick={handleClick}
            className=" bg-gradient-to-r from-purple-600 to-fuchsia-600 px-5 py-3 text-white text-md rounded shadow-md shadow-purple-900 mb-4"
          >
            eMandate
          </button>
        </div>
        </>
    )
}

export default Dashboard
