import React from 'react'

function Header({children}) {
    return (
        <header className=' bg-[#282a36] sticky top-0 left-0 right-0 shadow-md shadow-gray-900 text-purple-600 flex justify-between'>
            <div className=" select-none font-bold text-2xl px-5 py-3">
                HDFC
            </div>
            { children }
        </header>
    )
}

export default Header
