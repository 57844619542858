import { Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Nav, { NavLink } from "./components/Nav";
import NavStyles from "./components/Nav.module.css";
import Dashboard from "./routes/Dashboard";
import Scheams from "./routes/Scheams";
import NewScheam from "./routes/NewScheam";

function App() {
  const clickOnBodyHandler = (e) => {
    if (!e.target.matches("header nav button")) {
      document
        .querySelector("header nav ul")
        .classList.remove(NavStyles.active);
    }
  };

  return (
    <div className="App" onClick={clickOnBodyHandler}>
      <Header>
        <Nav>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/scheams">Scheams</NavLink>
          <NavLink to="/promotion">Promotion</NavLink>
        </Nav>
      </Header>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/scheams" element={<Scheams />} />
        <Route path="/scheams/new" element={<NewScheam />} />
      </Routes>
    </div>
  );
}

export default App;
