import React, { useState } from "react";
import Tabs, { Tab } from "../components/Tabs";
import Button from '../components/Button'
import Input from "../components/Input";

function NewScheam() {
  const saveScheam = (e) => {
    console.log("Scheam Saved!");
  };
  
  const [personal, setPersonal] = useState({
    name: '',
    mobile: '',
  });
  const [asset, setAsset] = useState({
    esp: 0,
    rto: 0,
    ins: 0,
    acc: 0,
    borp: 0,
    dorp: 0,
  })
  const [charges, setCharges] = useState({
    dp: 0,
    pf: { per: 0, amo: 0 },
    doc: { per: 0, amo: 0 },
    pdd: 0,
    stamp: 0,
    xsell: 0,
    decr: 0,
    gla: 0,
    nla: 0,
    disb: 0,
    gp: 0
  })
  const [emi, setEMI] = useState({
    emi12: 0,
    emi18: 0,
    emi24: 0,
    emi30: 0,
    emi36: 0
  })

  return (
    <>
      <div className="bg-[#22212c]">
        <div className=" py-5">
          <h1 className=" text-purple-600 text-5xl font-bold">New Scheam</h1>
        </div>
        <svg
          viewBox="0 0 1792 335"
          xmlns="http://www.w3.org/2000/svg"
          className=" bg-[#282a36]"
        >
          <path
            fill="rgba(34, 33, 44, 1)"
            d="M 0 50 C 259 50 259 209 518 209 L 518 209 L 518 0 L 0 0 Z"
            stroke-width="0"
          ></path>{" "}
          <path
            fill="rgba(34, 33, 44, 1)"
            d="M 517 209 C 800 209 800 96 1083 96 L 1083 96 L 1083 0 L 517 0 Z"
            stroke-width="0"
          ></path>{" "}
          <path
            fill="rgba(34, 33, 44, 1)"
            d="M 1082 96 C 1437 96 1437 189 1792 189 L 1792 189 L 1792 0 L 1082 0 Z"
            stroke-width="0"
          ></path>
        </svg>
        <div className=" bg-[#282a36] px-5">
          <Button onClick={saveScheam} >Save Scheam</Button>
          <Tabs
            data={[
              {
                id: 0,
                label: "personal",
                default: "yes",
              },
              {
                id: 1,
                label: "asset",
              },
              {
                id: 2,
                label: "charges",
              },
              {
                id: 3,
                label: "emi",
              },
            ]}
          >
            <Tab label="personal" active={true}>
              <Input label="Name" name="name" value={personal.name} onChange={e => setPersonal({...personal, name: e.target.value})}/>
              <Input label="Mobile" name="mobile" value={personal.mobile} onChange={e => setPersonal({...personal, mobile: e.target.value})}/>
            </Tab>
            <Tab label="asset">
              <Input label="Model" name="model" value={asset.model} onChange={e => setAsset({...asset, model: e.target.value})}/>
              <Input label="ESP" name="esp" value={asset.esp} onChange={e => setAsset({...asset, esp: Number.parseInt(e.target.value)})}/>
              <Input label="RTO" name="rto" value={asset.rto} onChange={e => setAsset({...asset, rto: Number.parseInt(e.target.value)})}/>
              <Input label="INS" name="esp" value={asset.esp} onChange={e => setAsset({...asset, esp: Number.parseInt(e.target.value)})}/>
              <Input label="ACC" name="esp" value={asset.esp} onChange={e => setAsset({...asset, esp: Number.parseInt(e.target.value)})}/>
            </Tab>
            <Tab label="charges">
              Charges <br />
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae
              necessitatibus quas nostrum earum recusandae. Quos aut porro
              repellendus culpa iusto harum, cum quasi saepe veritatis, pariatur
              consectetur fuga ratione reiciendis?
            </Tab>
            <Tab label="emi">
              EMI <br />
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae
              necessitatibus quas nostrum earum recusandae. Quos aut porro
              repellendus culpa iusto harum, cum quasi saepe veritatis, pariatur
              consectetur fuga ratione reiciendis?
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default NewScheam;
