import React, { useState } from "react";
import Styles from "./Tabs.module.css";

function TabPill ({label, changeTab}) {

  return(
    <li data-label={label} onClick={changeTab} className={Styles.tab}>
      {label.charAt(0).toUpperCase() + label.slice(1)}
    </li>
  )
}

export function Tab({label, active, children}) {
  if(active) {
    return(
      <div
        data-label={label}
        className={`${Styles.tabContent} ${Styles.active}`}
      >
        { children }
      </div>
    )
  } else {
    return(
      <div
        data-label={label}
        className={Styles.tabContent}
      >
        { children }
      </div>
    )
  }
}

function Tabs({data, children}) {
  const [activeTab, setActiveTab] = useState("personal");

  const changeTab = (e) => {
    document.querySelectorAll(`li[data-label]`).forEach(tab => {
        tab.classList.remove(Styles.activeTab)
    })
    document
      .querySelector(`li[data-label=${e.target.dataset.label}]`)
      .classList.add(Styles.activeTab);
    document
      .querySelector(`div[data-label=${activeTab}]`)
      .classList.toggle(Styles.active);
    document
      .querySelector(`div[data-label=${e.target.dataset.label}]`)
      .classList.toggle(Styles.active);
    setActiveTab(e.target.dataset.label);
  };

  return (
    <div className={Styles.tabs}>
      <div className=" block">
        <ul className={Styles.tabNav}>
          {data.map(tab => <TabPill changeTab={changeTab} key={tab.id} label={tab.label} />) }
        </ul>
      </div>
      <div className={Styles.tabContents}>
        { children }
      </div>
    </div>
  );
}

export default Tabs;
